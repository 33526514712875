import "core-js/stable";
import "regenerator-runtime/runtime";

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
// require("turbolinks").start(); // 🛠️
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
ActiveStorage.start();

// jquery ui css
require("jquery-ui/dist/themes/base/jquery-ui.min.css");

// Bootstrap
import "bootstrap";
document.addEventListener("DOMContentLoaded", () => {
  $('[data-toggle="tooltip"]').tooltip();
});
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-theme";

// jasny-bootstrap
import "jasny-bootstrap/dist/js/jasny-bootstrap";
import "jasny-bootstrap/dist/css/jasny-bootstrap";

/* For AdminLTE 2.4 */
require("admin-lte");
import "admin-lte/dist/css/AdminLTE.css";
import "admin-lte/dist/css/skins/_all-skins.css";
import "font-awesome/css/font-awesome.css";

// Select2 & theme
require("select2");
require("select2/dist/css/select2.min.css");
require("select2-bootstrap-theme/dist/select2-bootstrap.min.css");

// Datatables
import "datatables.net-bs";

// code splitting
document.addEventListener("DOMContentLoaded", function () {
  var pages = [
    "basic_prices",
    "hamlets",
    "home_koperasi",
    "list_members",
    "member_groups",
    "member_statuses",
    "milk_deposits",
    "milk_purchase_routes",
    "milk_purchases",
    "milk_qualities",
    "milk_quality_test_formats",
    "milk_quality_tests",
    "milk_receiving_times",
    "milk_rejection_lists",
    "milk_storages",
    "outlet_consumers",
    "outlets",
    "shelters",
    "users",
    "villages",
  ];
  pages.forEach(function (page) {
    if (document.querySelectorAll("#" + page + "_controller").length > 0) {
      import("../pages/" + page); // webpack will load this JS async
      
      if(page === "milk_quality_tests") {
        import("../pages/script_milkotester_master_classic");
      }
    }
  });
});

// Daterange Picker
require("daterangepicker");

// Monthrange Picker
require("jquery-ui-monthpicker");

// toastr
import toastr from "toastr";

// Data confirm modal
require("data-confirm-modal");

// Chartkick
import Chartkick from "chartkick";
import Chart from "chart.js";
Chartkick.use(Chart);

// QRcode
import QRCode from "qrcode";
window.QRCode = QRCode;

// Bootstrap Switch
require("bootstrap-switch");
require("bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min");

// Accounting.js
require("accounting");

// From application.js assets
dataConfirmModal.setDefaults({
  title: "Apakah Anda Yakin?",
  commit: "Ya",
  cancel: "Tidak",
  commitClass: "btn btn-primary",
});

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

window.guide = function (state) {
  var url = window.location.href;

  if (state) {
    if (url.indexOf("?") > -1) {
      url += "&tour=true";
    } else {
      url += "?tour=true";
    }
  } else {
    url = window.location.href.split("?")[0];
  }

  window.location.href = url;
};

// $(function() {
// $('input[type=number]').each(function () {
//   console.log(this)
//   // $(this).change(function () {
//     // console.log($(this).val().toString())
//     var $replace = $(this).val().toString().replace(/,/g, '.');
//     $(this).val($replace);
//   // })
// });
// });

// $(document).on("input[type=number]", "keypress", (function (event) {
//   return isNumber(event, this);
// }));
//
// // THE SCRIPT THAT CHECKS IF THE KEY PRESSED IS A NUMERIC OR DECIMAL VALUE.
// function isNumber(evt, element) {
//   var charCode = (evt.which) ? evt.which : event.keyCode
//
//   if (
//       (charCode != 46 || $(element).val().indexOf('.') != -1) &&      // “.” CHECK DOT, AND ONLY ONE.
//       (charCode < 48 || charCode > 57)
//   ) {
//     return false;
//   } else {
//     return true;
//   }
// }

window.printpage = function () {
  window.print();
};

window.showBackdropAndLoadingIndicator = function () {
  var backdrop = document.createElement("div");
  backdrop.classList.add("backdrop");
  document.body.appendChild(backdrop);

  var loading = document.createElement("div");
  loading.classList.add("loading");
  backdrop.appendChild(loading);
};

window.hideBackdropAndLoadingIndicator = function () {
  var backdrop = document.querySelector(".backdrop");
  if (backdrop) {
    document.body.removeChild(backdrop);
  }

  var loading = document.querySelector(".loading");
  if (loading) {
    document.body.removeChild(loading);
  }
};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
